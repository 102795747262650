// imports
import Link from 'next/link';

// lib files
import { Franchise } from '@/lib/types/api/franchise-data';
import { ShowNode } from '@/lib/types/graphql/searchShowsResponse';
import { ShowRowContent, ShowDetails, ShowOrFranchiseEnum } from '@/lib/types/api/show-data';

// components
import ITSImage from '@/components/ITSImage/ITSImage';

// styles
import styles from './ShowPoster.module.scss';
type ShowWithImages = ShowDetails | ShowRowContent;
export interface ShowPosterType {
  images: {
    'show-poster2x3': string;
    'asset-mezzanine-16x9': string;
    'show-mezzanine16x9': string;
  };
  slug: string;
  title: string;
  item_type: ShowOrFranchiseEnum;
}
interface ShowPosterProps {
  show: ShowWithImages | ShowNode | Franchise | ShowPosterType;
  href?: string;
  gtmLabel?: string;
  className?: string;
}
const ShowPoster: React.FC<ShowPosterProps> = props => {
  const {
    show,
    href,
    gtmLabel,
    className
  } = props;
  const {
    item_type
  } = show;
  let imgSrc = null;
  let parentSlug = 'show';
  switch (item_type?.toLowerCase()) {
    case ShowOrFranchiseEnum.Franchise:
      const franchise = show as Franchise;
      imgSrc = franchise.images?.['franchise-poster2x3'] || franchise.image;
      parentSlug = 'franchise';
    case ShowOrFranchiseEnum.Show:
      // sorry for all of this - typescript is a right bastard
      if (Object.hasOwn(show, 'images')) {
        const showWithImages = show as ShowWithImages;
        if (showWithImages.images?.['show-poster2x3']) {
          imgSrc = showWithImages.images['show-poster2x3'];
        }
      } else if (Object.hasOwn(show, 'image')) {
        const showWithImage = show as ShowNode;
        imgSrc = showWithImage.image as string;
      }
  }
  const linkHref = href || `/${parentSlug}/${show.slug}/`;
  let classNames = styles.show_poster__link;
  if (className) {
    classNames += ` ${className}`;
  }
  if (imgSrc) {
    classNames += ` ${styles.show_poster__with_image}`;
  }
  return <Link href={linkHref} className={classNames} data-gtm-label={gtmLabel} data-sentry-element="Link" data-sentry-component="ShowPoster" data-sentry-source-file="ShowPoster.tsx">
      {imgSrc ? <ITSImage src={imgSrc} alt={show.title} width={227} height={340} resizeWithCrop={true} /> : <p className={styles.show_poster__fallback}>{show.title}</p>}
    </Link>;
};
export default ShowPoster;